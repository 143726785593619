// Helper function to normalize text by removing accents and special characters
const normalizeText = (text) => {
  if (!text) return '';
  return text.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // Remove accents
      .toLowerCase()
      .trim();
};

// Find the matching state/province from the available options
const findMatchingState = (inputState, availableStates) => {
  if (!inputState || !availableStates || !availableStates.length) return null;

  // First try exact match
  const exactMatch = availableStates.find(state => state === inputState);
  if (exactMatch) return exactMatch;

  // Then try case-insensitive match
  const lowercaseMatch = availableStates.find(
      state => state.toLowerCase() === inputState.toLowerCase()
  );
  if (lowercaseMatch) return lowercaseMatch;

  // Finally try matching without accents
  const normalizedInput = normalizeText(inputState);
  const matchingState = availableStates.find(
      state => normalizeText(state) === normalizedInput
  );
  return matchingState || null;
};

export { normalizeText, findMatchingState };